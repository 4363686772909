<template>
  <v-container
    fluid
    tag="section"
  >

<base-material-card
      icon="mdi-check"
      title="Consultar Cartão TEM"
      class="px-5 py-3"
    >



    <v-container>
    <v-form @submit.prevent="consulta_tem">
      <v-text-field
        v-model="pesquisa"
        label="Digite o CPF ou Contrato"
        outlined
      ></v-text-field>
      <v-btn :loading="load_consulta"
        @click="consulta_tem"
        color="primary"
        class="mt-3"
      >
        Consultar
      </v-btn>

      <!-- Exibe o cartão com informações, se houver -->
      <v-card v-if="cartao" class="mt-5 pa-3" outlined>
        <v-card-title>
          Informações Encontradas
        </v-card-title>
        <br/>
        <v-card-subtitle>
          <div><strong>Nome:</strong> {{ cartao.nome }}</div>
          <div><strong>CPF:</strong> {{ cartao.cpf }}</div>
          <div><strong>Contrato:</strong> {{ cartao.contrato }}</div>
          <div><strong>Status:</strong> {{ cartao.status }}</div>
          <div><strong>Cartão:</strong> {{ cartao.cartao }}</div>
        </v-card-subtitle>

        <!-- Botões baseados no status do cartão -->
        <v-card-actions>
          <v-btn :loading="load_consulta"
            v-if="cartao.status === 'SUSPENSO'"
            @click="ativar_cartao"
            color="primary"
          >
            Ativar Cartão
          </v-btn>

          <v-btn :loading="load_consulta"
            v-if="cartao.status === 'ATIVO'"
            @click="suspender_cartao"
            color="orange"
          >
            Suspender Cartão
          </v-btn>

          <v-btn :loading="load_consulta"
            v-if="cartao.status === 'ATIVO'"
            @click="cancelar_cartao"
            color="red"
          >
            Cancelar Cartão
          </v-btn>


          <v-btn :loading="load_consulta"
            v-if="cartao.status === 'PENDENTE'"
            @click="adesao_cartao"
            color="primary"
          >
            Confirmar Adesão
          </v-btn>
        </v-card-actions>

      </v-card>

      <!-- Exibe uma mensagem se a resposta for nula -->
      <v-alert
        v-if="!cartao && mensagem"
        type="error"
        class="mt-5"
      >
        {{ mensagem }}
      </v-alert>


    </v-form>
  </v-container>

        
      
  </base-material-card>
  </v-container>
</template>
<script>
  import Contrato from '../../../services/contrato'
  import axios from 'axios'
  import authHeader from '../../../services/auth-header';
  import config  from '../../../services/config'


  export default {


     created() {
          if(this.$store.state.auth.status.loggedIn == false){
            this.$router.push('/')
          }
      },

    data: () => ({
      pesquisa: '',
      cartao: null,
      mensagem: '',
      msg_dialog: '',
      dialog: false,
      loading3: false,
      load_consulta: false
    }),

    mounted () {
    },

    methods: {


      async consulta_tem() {
        this.load_consulta = true;
          try {
            const response = await Contrato.consulta_tem(this.pesquisa);
            if (response.data) {
              this.cartao = response.data;
              this.mensagem = ''; // Limpa a mensagem de erro, se houver
            } else {
              this.cartao = null;
              this.mensagem = 'Nenhuma informação encontrada para a consulta.';
            }
            this.load_consulta = false;

          } catch (e) {
            if (e.response && e.response.status === 401) {
              this.$store.dispatch('auth/logout');
              this.$router.push('/restrito/login');
            } else {
              this.mensagem = 'Erro ao realizar a consulta. Tente novamente.';
            }
            this.cartao = null; // Limpa os dados do cartão em caso de erro
            this.load_consulta = false;

      }
    },

    async ativar_cartao() {
      this.load_consulta = true;
      this.mensagem = '';
        try {
          // Chama o método para ativar o cartão
          await Contrato.ativar_cartao(this.cartao.cpf, this.cartao.cartao.replace(/ /g, ""));
        } catch (e) {
          this.mensagem = 'Erro ao ativar o cartão.';
          this.load_consulta = false;
        } finally {
          this.consulta_tem();
        }
      },

      async suspender_cartao() {

        const confirmed = confirm('Você tem certeza que deseja suspender o cartão?');

          if (!confirmed) {
            return; // Se o usuário cancelar, não faz nada
          }

      this.load_consulta = true;
      this.mensagem = '';
        try {
          // Chama o método para ativar o cartão
          await Contrato.suspender_cartao(this.cartao.cpf, this.cartao.cartao.replace(/ /g, ""));
        } catch (e) {
          this.mensagem = 'Erro ao ativar o cartão.';
          this.load_consulta = false;
        } finally {
          this.consulta_tem();

        }
      },

      async cancelar_cartao() {

        const confirmed = confirm('Você tem certeza que deseja cancelar o cartão?');

        if (!confirmed) {
          return; // Se o usuário cancelar, não faz nada
        }


      this.load_consulta = true;
      this.mensagem = '';
        try {
          // Chama o método para ativar o cartão
          await Contrato.cancelar_cartao(this.cartao.cpf, this.cartao.cartao.replace(/ /g, ""));
        } catch (e) {
          this.mensagem = 'Erro ao ativar o cartão.';
          this.load_consulta = false;
        } finally {
          this.consulta_tem();
        }
      },
      
    async adesao_cartao() {
      this.load_consulta = true;
      this.mensagem = '';
        try {
          
          const response =  await Contrato.adesao_cartao(this.cartao.cpf, this.cartao.cartao.replace(/ /g, ""));
          if (response.data.statusCartao && response.data.statusCartao.includes('Client error')) { 
            alert(response.data.statusCartao); 
          }

        } catch (e) {
          this.mensagem = 'Erro ao fazer adesão do cartão.';
          this.load_consulta = false;

        } finally {
          this.consulta_tem();
        }
  
    },
   
    },
  }

</script>
